import React from 'react'
import { classNames } from '@/components/shared/classNames'

import styles from '@/components/atoms/forms/InputErrorText.module.scss'

interface InputErrorTextProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
}

const InputErrorText = ({ className, ...props }: InputErrorTextProps) => {
  if (!props.children) return null
  return <p className={classNames(className, styles['input-error'])}>{props.children}</p>
}

export default InputErrorText
