import React, { ReactNode } from 'react'

import { classNames } from '@/components/shared/classNames'

import styles from './Label.module.scss'

type HtmlLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

export interface LabelProps extends HtmlLabelProps {
  children: string | ReactNode
  isValid?: boolean
  required?: boolean
  isFocused?: boolean
  hidden?: boolean
  rowMode?: boolean
  floating?: boolean
}

export const Label = ({
  isValid = true,
  required = false,
  isFocused = false,
  hidden = false,
  children,
  rowMode = false,
  floating = false,
  ...props
}: LabelProps) => {
  const isFocusedClass = isFocused ? styles['label--focused'] : ''
  const isValidClass = isValid ? '' : styles['label--invalid']
  const isHiddenClass = hidden ? styles['label--hidden'] : ''
  const isFloatingClass = floating ? styles['label--floating'] : ''
  const isRowModeClass = rowMode ? styles['label--inline'] : ''
  const componentProps = {
    className: classNames(
      'grow-0',
      styles.label,
      isFloatingClass,
      isFocusedClass,
      isValidClass,
      isHiddenClass,
      isRowModeClass
    ),
    ...props
  }

  return (
    <label {...componentProps}>
      {children}
      {required && <span className={styles['label--required']}>*</span>}
    </label>
  )
}
