'use client'
import Title from '@/components/atoms/Title'
import LoginForm from '@/components/organisms/web-forms/login/LoginForm'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'

export default function SignIn() {
  const router = useRouter()

  const { data: session } = useSession()
  if (session) {
    router.push('/')
    return null
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2">
      <div className="flex flex-col items-center justify-center">
        <Title className="text-4xl font-bold">Sign in</Title>
        <LoginForm className="max-w-sm" />
      </div>
    </div>
  )
}
