import React from 'react'
import { classNames } from '../../shared/classNames'
interface Props {
  className?: string
  children?: React.ReactNode
}

const HelpText = ({ className, children }: Props) => {
  if (!children) return null
  return <div className={classNames('text-sm text-gray', className)}>{children}</div>
}

export default HelpText
