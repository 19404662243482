import React from 'react'
import { classNames } from '@/components/shared/classNames'

import styles from '@/components/atoms/forms/FormGroup.module.scss'
interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
  className?: string
  row?: boolean
}

const FormGroup = ({ className, row = false, ...props }: FormGroupProps) => {
  const flexClasses = row ? 'flex justify-start' : 'flex flex-col'
  return <div className={classNames(flexClasses, 'gap-2', className, styles['form-group'])}>{props.children}</div>
}

export default FormGroup
