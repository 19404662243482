/* eslint-disable no-console */
import { classNames } from '@/components/shared/classNames'
import React, { useState } from 'react'

import { Button } from '@/components/atoms/Button'
import styles from './FileInput.module.scss'

interface IFileInputProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string
  name: string
  resetField: (name: string) => void
  required?: boolean
  placeholder: string
  onChange: (e: any) => void
  className?: string
}

const FileInput = React.forwardRef(
  ({ name, label = '', required, resetField, placeholder, onChange, className }: IFileInputProps, ref: any) => {
    const [file, setFile] = useState<File | null>()
    const handleOnChange = (e: any) => {
      setFile(e.target.files[0] as File)
      onChange(e.target.files[0])
    }

    const onClickButton = () => {
      const input = document.getElementById(name) as HTMLInputElement
      input.click()
    }

    const handleClear = () => {
      setFile(null)
      resetField(name)
    }

    return (
      <div className={classNames(className, styles['file-input'])}>
        <label htmlFor={name} className="file-input__label relative">
          <input
            ref={ref}
            type="file"
            className="opacity-0 no-size absolute"
            id={name}
            name={name}
            onChange={handleOnChange}
          />
          <div className={styles['file-input--input-group']}>
            <Button variant="upload-file" className="relative w-full" size="small" onClick={onClickButton}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 402.44 282.56" className="w-10 h-4 -ml-6">
                <path
                  fill="currentColor"
                  d="m206.02,280.96c-36.83,0-73.66.06-110.49-.03-13.08-.03-25.88-1.92-38.1-6.89-17.51-7.12-31.39-18.61-41.6-34.52-9.53-14.86-14.09-31.34-14.06-48.77.03-20.86,6.64-39.75,19.92-56.08,10.1-12.42,22.63-21.51,37.5-27.36,1.69-.67,2.42-1.52,2.58-3.36,1.09-12.34,3.79-24.29,8.56-35.77,5.98-14.41,14.91-26.7,26.27-37.28,8.53-7.95,18.11-14.36,28.72-19.25,10.15-4.68,20.8-7.45,31.83-8.97,22.72-3.11,44.31.47,64.65,11.06,14.78,7.69,27.29,18.16,37.05,31.72,1.62,2.26,2.84,2.86,5.56,1.63,25.84-11.63,59.37-4.56,78.93,18.17,14.06,16.34,19.14,35.01,16.75,56.07-.27,2.39-.65,4.79-1.3,7.1-.48,1.69-.15,2.56,1.4,3.37,8.76,4.58,16.14,10.84,22.32,18.54,9.43,11.74,15.64,24.88,17.48,39.95,2.3,18.83-1.11,36.54-10.85,52.8-8.22,13.71-19.77,23.91-34.44,30.48-12.07,5.41-24.8,7.42-37.94,7.4-36.91-.03-73.83-.01-110.74-.01Zm-.13-19.82c37.16,0,74.32.03,111.49-.04,4.48,0,9.01-.28,13.43-.99,18.9-3.02,32.97-13.2,42.53-29.61,4.68-8.04,6.9-16.87,7.43-26.17.75-13.34-2.36-25.56-10.02-36.59-5.36-7.72-12.28-13.7-20.03-18.92-3.26-2.2-6.44-4.82-8.85-7.89-4.22-5.36-3.44-11.65-2.21-17.95,3.87-19.87-.72-37.43-16.72-50.28-15.41-12.38-32.96-14.86-51.65-7.19-3.37,1.38-7.22,2.08-10.87,2.19-7.02.22-12.09-3.79-16.06-9.13-8.24-11.09-18.51-19.69-30.6-26.34-13.22-7.28-27.33-11.01-42.32-10.9-20.41.16-39.08,5.96-55.28,18.68-12.35,9.69-21.72,21.72-27.75,36.31-3.95,9.56-5.97,19.54-6.92,29.78-.51,5.53-1.91,10.9-6.44,14.42-3.23,2.51-7.05,4.4-10.84,6.02-12.39,5.3-22.41,13.56-30,24.49-10.82,15.58-14.65,32.96-11.68,51.77,2.96,18.78,12.8,33.35,27.65,44.72,12.58,9.64,27.12,13.56,42.77,13.59,37.66.07,75.32.02,112.99.02Z"
                />
                <path
                  fill="currentColor"
                  d="m189.97,115.5c-4.67,4.98-9.25,10.05-14.05,14.91-5.67,5.76-11.47,11.38-17.31,16.97-6.04,5.79-14.18,3.96-16.69-3.69-1.4-4.27.74-7.42,3.53-10.24,6.97-7.05,14-14.04,21-21.06,8.89-8.9,17.77-17.81,26.66-26.71,5.67-5.68,10.41-5.68,16.12.03,15.85,15.84,31.7,31.68,47.53,47.54,2.98,2.98,5.39,6.25,3.65,10.85-2.49,6.57-9.78,8.85-15.16,4.4-4.22-3.49-8.01-7.52-11.95-11.35-6.69-6.5-13.34-13.04-20.02-19.56-.58-.57-1.2-1.09-2.26-2.05v3.35c0,30.24,0,60.48,0,90.71,0,3.91-.79,7.52-4.42,9.73-3.38,2.06-6.9,2.16-10.41.22-3.83-2.12-4.89-5.72-4.96-9.71-.1-5.75-.03-11.49-.03-17.24,0-24.49,0-48.98,0-73.47v-2.88l-1.23-.77Z"
                />
              </svg>
              {file ? file.name : placeholder}
            </Button>
            {file?.name && (
              <span className={styles['file-input--input-group--close']} onClick={handleClear}>
                X
              </span>
            )}
          </div>
        </label>
      </div>
    )
  }
)

FileInput.displayName = 'FileInput'
export default FileInput
