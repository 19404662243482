'use client'
import Loader from '@/components/atoms/Loader'
import { showUIAlert, useSubmitForm } from '@/components/atoms/forms/FormUtils'
import { RequestedFormsConfigType, useMergeFormsConfig } from '@/components/atoms/forms/InputConfigs'
import { signIn } from 'next-auth/react'
import { useRouter, useSearchParams } from 'next/navigation'
import { HTMLAttributes, useEffect } from 'react'
import BaseFormStyled from '../elements/BaseFormStyled'
import FormBody from '../elements/FormBody'

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const LoginForm = ({ className, ...props }: Props) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const baseFormInputConfigurations: RequestedFormsConfigType = [
    {
      name: 'username',
      className: 'flex-1 text-sm',
      label: 'Username',
      placeholder: 'Username'
    },
    {
      name: 'password',
      className: 'flex-1 text-sm',
      label: 'Password',
      placeholder: 'Password'
    }
  ]
  const inputConfiguration = useMergeFormsConfig(baseFormInputConfigurations)

  const handleSignIn = async ({ username, password }: Record<string, string>) => {
    await signIn('credentials', { email: username, password })
    router.push('/')
  }
  const handleSignInWithToken = async ({ token }: Record<string, string>) => {
    const response = await signIn('credentials', { email: '', password: '', redirect: false, token })

    if (response?.status === 401) {
      throw new Error('Email or Password is incorrect')
    }
    if (response?.error) {
      throw new Error(response.error)
    }
  }

  const { handleSubmit, isLoading } = useSubmitForm({
    service: handleSignIn,
    aditionalPayloadData: {},
    onErrorCallback: () => {
      showUIAlert({
        title: 'Error',
        titleText: 'Username or password incorrect'
      })
    },
    onSuccessCallback: () => {
      console.log('Login success')
    }
  })

  const message = isLoading ? 'Loading...' : 'Sign in'
  useEffect(() => {
    const error = searchParams.get('error')
    if (error) {
      showUIAlert({
        title: 'Error',
        titleText: 'Username or password incorrect'
      })
    }
  }, [])

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      handleSignInWithToken({ token }).catch(() => {
        showUIAlert({
          title: 'Error',
          titleText: 'Unable to sign in with token'
        })
      })
    }
  }, [])

  return (
    <div className={className} {...props}>
      {isLoading && <Loader />}
      <FormBody className="flex flex-col">
        <BaseFormStyled
          className="flex-1"
          InputsConfig={inputConfiguration}
          onSubmit={handleSubmit}
          submitText={message}
          submitClassName="text-lg w-full"
        />
      </FormBody>
    </div>
  )
}

export default LoginForm
