import { classNames } from '@/components/shared/classNames'
import NextLink from 'next/link'
import React from 'react'
import styles from './Button.module.scss'

type HtmlButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  href?: undefined
}

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string
}

export type ButtonVariant =
  | 'primary-light'
  | 'primary'
  | 'primary-dark'
  | 'secondary'
  | 'tertiary'
  | 'primary-outline'
  | 'secondary-outline'
  | 'tertiary-outline'
  | 'light-outline'
  | 'upload-file'
  | 'keyboard'
  | 'menu'
  | 'pagination'
  | 'danger'
  | 'danger-outline'
  | 'success'

export interface ButtonProps {
  variant?: ButtonVariant
  size?: 'base' | 'large' | 'small' | 'noSize'
  children: string | React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  download?: boolean
  blank?: boolean
  pill?: boolean
  rightIcon?: React.ReactNode
  iconAnimationType?: 'none' | 'to-right' | 'pulse'
  active?: boolean
  disabled?: boolean
}

const hasHref = (props: HtmlButtonProps | AnchorProps): props is AnchorProps => 'href' in props

const buttonSizes = {
  noSize: '',
  small: 'px-2 py-0.5',
  base: 'px-4 py-1',
  large: 'px-6 py-2'
}

export const Button = ({
  variant = 'primary-dark',
  size = 'base',
  className = '',
  type = 'button',
  download = false,
  blank = false,
  pill = false,
  rightIcon = null,
  iconAnimationType = 'none',
  active = false,
  disabled = false,
  ...props
}: (ButtonProps & HtmlButtonProps) | (ButtonProps & AnchorProps)) => {
  const enableChildHoverAnimationClass = 'group transition-all duration-300' // group class is used to enable child hover animation
  const pillClasses = pill ? 'rounded-full' : 'rounded-md'
  const componentProps = {
    className: classNames(
      'font-light',
      enableChildHoverAnimationClass,
      styles.btn,
      styles[`btn--${variant}`],
      active ? styles.active : '',
      pillClasses,
      buttonSizes[size],
      disabled ? 'opacity-50 cursor-not-allowed' : '',
      className,
      'fade-appear'
    ),
    type,
    download,
    disabled,
    target: blank ? '_blank' : '_self',
    ...props
  }

  componentProps.className = classNames(
    componentProps.className,
    hasHref(componentProps) ? 'cursor-pointer' : 'cursor-default'
  )

  if (hasHref(componentProps)) {
    return (
      <NextLink href={componentProps.href} passHref={true} legacyBehavior={true}>
        <a {...componentProps}>
          {' '}
          {componentProps.children} {rightIcon}
        </a>
      </NextLink>
    )
  }

  return <button {...componentProps}> {componentProps.children} </button>
}
